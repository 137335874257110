.contact {
    background-color: #e7e5e5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 60px 20px;
  }
  

  .contact h1 {
    font-size: 2.5rem;
    margin-bottom: 30px;
    color: #333;
  }
  
  .contactSection {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 600px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
  }
  

  .contactInfo {
    width: 45%;
  }
  
  .contactInfo p {
    font-size: 1.1rem;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
  }
  

  .contactInfo button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    font-size: 1.2rem;
    border: none;
    border-radius: 50%;
    background-color: #00aaff;
    color: white;
    margin-right: 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease;
  }
  
  .contactInfo button:hover {
    background-color: #0796dd;
  }
  

  .contactFormCard {
    width: 90%;
    background: white;
    padding: 30px 10px;
    border-radius: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  }
  
  .contactFormCard h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  .contactFormCard form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  

  .contactFormCard input {
    padding: 15px;
    font-size: 1rem;
    border: 2px solid #eaeaea;
    border-radius: 8px;
    transition: border-color 0.3s ease;
  }
  
  .contactFormCard input:focus {
    border-color: #049eeb;
  }
  
  .contactFormCard input[type="file"] {
    padding: 10px;
  }

  .sendButton {
    background-color: #0698e0;
    color: white;
    padding: 15px;
    font-size: 1rem;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .sendButton:hover {
    background-color: #087cb6;
  }
  
  /* Media Queries for Responsiveness */
  

  @media (max-width: 768px) {
    .contactSection {
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 30px;
    }
  
    .contactInfo {
      width: 100%;
      margin-bottom: 30px;
    }
  
    .contactFormCard {
      width: 100%;
    }
  }
  

  @media (max-width: 480px) {
    .contact h1 {
      font-size: 2rem;
    }
  
    .contactFormCard h2 {
      font-size: 1.6rem;
    }
  
    .contactInfo p {
      font-size: 1rem;
    }
  
    .sendButton {
      padding: 12px;
      font-size: 0.9rem;
    }
  }
  

  /* Social Media Section */
.socialMediaSection {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .socialMediaSection h2 {
    font-size: 2.2rem;
    color: #333;
    margin-bottom: 20px;
  }
  
  .socialMediaLinks {
    display: flex;
    justify-content: center;
    gap: 40px;
  }
  
  .socialMediaLinks a {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    font-size: 1.5rem;
    color: white;
    border-radius: 50px;
    text-decoration: none;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .socialMediaLinks a i {
    margin-right: 10px;
    font-size: 2rem;
  }
  
  /* Instagram */
  .socialMediaLinks a:nth-child(1) {
    background: linear-gradient(45deg, #f09433 0%, #e6683c 30%, #dc2743 50%, #cc2366 70%, #bc1888 100%);
  }
  
  .socialMediaLinks a:nth-child(1):hover {
    transform: scale(1.05);
  }
  
  /* Facebook */
  .socialMediaLinks a:nth-child(2) {
    background-color: #3b5998;
  }
  
  .socialMediaLinks a:nth-child(2):hover {
    transform: scale(1.05);
  }
  
  /* WhatsApp */
  .socialMediaLinks a:nth-child(3) {
    background-color: #25D366;
  }
  
  .socialMediaLinks a:nth-child(3):hover {
    transform: scale(1.05);
  }
  
  /* Rest of the styles remain unchanged */
  

  @media (max-width: 768px) {
    .socialMediaLinks{
      display: flex;
      flex-direction: column;
    }
  }