/* Banner.module.css */
.banner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 80px;
  background-image: url('../../../public/images/ShopBannerBackground.jpg');
  background-color: #f0f0f0; /* Adjust background color as needed */
  height: 300px; /* Adjust height as needed */
  position: relative; /* Ensure positioning context for absolute elements */
}

.textContainer {
  display: flex;
  flex-direction: column;
  max-width: 50%;
  justify-content: center;
  align-items: center;
}

.title, .subtitle {
  font-size: 3rem;
  margin-bottom: 10px;
  color: lightseagreen;
  margin: 0;
  padding: 0;
}


.imageContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}

.mainImage {
  max-width: 100%;
  height: auto;
}

.logoContainer {
  display: flex;
  padding: 10px;
  gap: 20px; /* Add some spacing between images */
}

.logo {
  width: 200px; /* Adjust size as needed */
  height: 250px; /* Maintain aspect ratio */
  border-radius: 10px;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.6);
  transition: transform 0.3s ease;
}

.logo:hover{
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.8);
  transform: scale(1.05);
}

/* Media Query for medium screens (up to 768px) */
@media (max-width: 925px) {
  .banner {
    flex-direction: column; /* Stack content vertically */
    padding: 1.5rem 3vw; /* Adjust padding for medium screens */
    height: auto; /* Allow height to adjust based on content */
    text-align: center;
  }

  .textContainer {
    max-width: 100%; /* Take up full width */
    margin-bottom: 1.5rem; /* Add spacing below text */
  }

  .title, .subtitle {
    font-size: 2rem; /* Slightly reduce font size for medium screens */
  }

  .imageContainer {
    justify-content: center; /* Center images */
    align-items: center;
  }

  .logoContainer {
    justify-content: center; /* Center the logos */
    flex-wrap: wrap; /* Wrap images to fit the smaller screen width */
    gap: 1.25rem; /* Adjust the spacing between logos */
  }

  .logo {
    width: 25vw; /* Adjust the size of the logos relative to viewport */
    height: 30vw; /* Maintain aspect ratio */
  }
}


/* Media Query for small screens (up to 480px) */
@media (max-width: 480px) {
  .banner {
    flex-direction: column; /* Stack content vertically */
    padding: 1rem 2vw; /* Reduce padding for smaller screens */
    height: auto; /* Allow height to adjust based on content */
    text-align: center;
  }

  .textContainer {
    max-width: 100%;
    margin-bottom: 1rem; /* Add spacing below text */
  }

  .title, .subtitle {
    font-size: 1.5rem; /* Reduce font size for smaller screens */
  }

  .imageContainer {
    justify-content: center; /* Center images */
  }

  .logoContainer {
    gap: 1rem; /* Reduce spacing between logos */
  }

  .logo {
    width: 40vw; /* Make logos larger relative to screen width */
    height: 45vw; /* Keep aspect ratio */
  }
}

