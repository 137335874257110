/* Apply a basic CSS reset for all elements */
* {
    margin-left: 0px;
    margin-right: 0px;
}

/* Ensure the body and main content stretch to full height */
html, body {
    height: 100%; /* Full viewport height */
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Make the root cover full viewport height */
}

main {
    flex: 1; /* Ensure main content takes up remaining space */
}
