.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 50px;
    background-color: #fff;
    border-bottom: 1px solid #ddd;
}

.navbarLeft {
    text-decoration: none;
    color: #333;
    width: 250px;
}

.logo img {
    height: auto;
    width: 100%;
}

.navMenu {
    list-style: none;
    display: flex;
    gap: 20px;
}

.navMenu li {
    position: relative;
}

.navMenu a {
    text-decoration: none;
    color: #333;
    font-size: 1.3rem;
    position: relative;
    display: inline-block;
    transition: color 0.3s ease;
}

.navMenu a::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: -5px;
    left: 50%;
    background-color: #00aaff; /* Underline color */
    transition: all 0.3s ease;
    transform: translateX(-50%);
}

.navMenu a:hover {
    color: #0056b3;
}

.navMenu a:hover::after {
    width: 100%; /* Underline animation on hover */
}

.dropdownHeader {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.dropdownButton {
    font-size: 1rem;
    font-weight: 600;
    margin-left: 5px;
    transition: transform 0.3s ease, color 0.3s ease;
}

.drpBtn{
    border-radius: 50%;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.drpBtn:hover{
    color: #00aaff;
    border-color: #00aaff;
}

.dropDownText{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid black;
    padding: 5px 5px;
    border-radius: 10px;
}

.dropdownButton.open {
    transform: rotate(180deg);
    color: #555;
}

.dropdownMenu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    width: 150px;
    z-index: 1;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.dropdownMenu li {
    padding: 10px;
}

.dropdownMenu li a {
    text-decoration: none;
    color: #333;
}

.dropdownMenu a::after{
    content: none;
}

.dropdownMenu li:hover {
    background-color: #f0f0f0;

}

.navbarRight {
    display: flex;
    gap: 10px;
}

.shopBtn {
    background: linear-gradient(45deg, #0056b3, #00aaff);
    color: white;
    font-size: 16px;
    font-weight: bold;
    padding: 12px 30px;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    outline: none;
    display: inline-block;
}

.shopBtn:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
    background: linear-gradient(45deg, #0056b3, #00aaff);
}

.shopBtn:active {
    transform: translateY(2px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.hamburger {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 24px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.hamburger span {
    display: block;
    height: 4px;
    width: 100%;
    background-color: #333;
    border-radius: 2px;
    transition: transform 0.3s ease, opacity 0.3s ease;
}

/* Animate the first bar to rotate and move down */
.hamburger.open .bar1 {
    transform: rotate(45deg) translate(9px, 5px);
}

/* Hide the middle bar */
.hamburger.open .bar2 {
    opacity: 0;
    display: none;
    transform: translateX(-20px);
}

/* Animate the third bar to rotate and move up */
.hamburger.open .bar3 {
    transform: rotate(-45deg) translate(9px, -5px);
}


.mobileMenu {
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: white;
    padding: 20px;
    position: absolute;
    top: 60px;
    right: 10px;
    width: 215px;
    border: 1px solid #ddd;
    z-index: 1000;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.mobileMenu a {
    text-decoration: none;
    color: #555;
    font-weight: 600;
    list-style-type: none;
}

.mobileDropdownMenu {
    display: none;
    position: absolute;
    top: 20%;
    left: 50%;
    background-color: white;
    padding: 10px;
    border: 1px solid #ddd;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    z-index: 1;
    list-style-type: none;
}


.mobileDropdownMenuW {
    display: none;
    position: absolute;
    top: 35.5%;
    left: 50%;
    background-color: white;
    padding: 10px;
    border: 1px solid #ddd;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    height: 7rem;
    list-style-type: none;
}

.mobileDropdownMenu.open {
    display: block;
}
.mobileDropdownMenuW.open {
    display: block;
}

.mobileDropdownMenu li {
    padding: 10px;
    background-color: white;
}


.mobileDropdownMenuW li {
    padding: 10px;
    background-color: white;
    gap: 0.5rem;
}

.mobileDropdownMenu li:hover {
    background-color: #f0f0f0;
}


@media (max-width: 700px) {

    .navbar {
        padding: 10px 20px;
    }
    .navbarLeft {
        width: 200px;
    }
    
    .logo img {
        height: auto;
        width: 100%;
    }
}

@media (max-width: 400px) {
    .navbarLeft {
        width: 150px;
    }
    
    .logo img {
        height: auto;
        width: 100%;
    }
}

