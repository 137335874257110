.heroSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 100px;
  background-image: url('../../../public/images/NeonBannerBackground.jpg');
}

.imageContainer {
  width: 300px;
  height: 300px;
  padding: 10px;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
}

.imageContainer::before {
  content: '';
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  background: conic-gradient(
    from 0deg at 50% 50%,
    #ff0000 0deg,
    #00eeff 90deg,
    #ffffff 180deg,
    #00eeff 270deg,
    #ff0000 360deg
  );
  z-index: 1;
  filter: blur(20px);
  border-radius: 25px;
  animation: animateConicalNeon 8s linear infinite;
}

.demoImage {
  width: 100%;
  height: auto;
  border-radius: 20px;
  position: relative;
  z-index: 1;
}

.textContainer {
  padding-left: 20px;
}

.textContainer h1 {
  margin: 0;
  font-size: 3.5rem;
}

.custom {
  color: white;
  text-shadow: 
    0 0 2px #00eeff, 
    0 0 4px #00eeff, 
    0 0 6px #00eeff, 
    0 0 8px #00eeff, 
    0 0 10px #00eeff, 
    0 0 12px #00eeff, 
    0 0 14px #00eeff;
}

.neon {
  color: white;
  text-shadow: 
    0 0 2px #ff0000, 
    0 0 4px #ff0000, 
    0 0 6px #ff0000, 
    0 0 8px #ff0000, 
    0 0 10px #ff0000, 
    0 0 12px #ff0000, 
    0 0 14px #ff0000;
}

.textContainer p {
  margin-top: 10px;
  font-size: 1.5rem;
  color: white;
}

/* Media query for screens up to 768px */
@media (max-width: 880px) {
  .heroSection {
    flex-direction: column; /* Stack text and image vertically */
    padding: 20px 40px; /* Adjust padding for smaller screens */
    text-align: center; /* Center text */
  }

  .textContainer {
    padding-left: 0; /* Remove left padding for smaller screens */
  }

  .textContainer h1 {
    font-size: 2.5rem; /* Reduce font size */
  }

  .textContainer p {
    font-size: 1.2rem; /* Reduce paragraph font size */
  }

  .imageContainer {
    width: 250px; /* Resize image container */
    height: 250px;
  }
}

/* Media query for very small screens (up to 480px) */
@media (max-width: 480px) {
  .heroSection {
    padding: 10px 20px; /* Further reduce padding */
  }

  .textContainer h1 {
    font-size: 2rem; /* Further reduce font size */
  }

  .textContainer p {
    font-size: 1rem; /* Further reduce paragraph font size */
  }

  .imageContainer {
    width: 200px; /* Further resize image container */
    height: 200px;
  }
}

@keyframes animateConicalNeon {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
