/* Animation for h1 and typing effect */
@keyframes slideInFromLeft {
    from {
        transform: translateX(-100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes typingAnimation {
    from {
        width: 0;
        opacity: 0;
    }
    to {
        width: 100%;
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Keyframes for blinking cursor */
@keyframes blink-caret {
    from, to { border-color: transparent; }
    50% { border-color: black; }
}

/* Applying the animations */
.heroText h1 {
    animation: slideInFromLeft 0.5s ease-out;
}

.typingEffect {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    border-right: 2px solid #000;
    animation: typingAnimation 2s steps(30, end), blink-caret 0.75s step-end infinite;
}

.heroImage {
    animation: fadeIn 1s ease-in-out;
}

.ctaButton {
    animation: fadeIn 1s ease-in-out;
}

/* Existing styles */
.hero {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 50px;
    background-color: #f0f0f0;
}

.heroText {
    max-width: 40%;
}

.heroText h1 {
    font-size: 3.5rem;
    margin: 10px 0px;
}

.typingEffect {
    font-size: 2rem;
    color: gray;
    font-weight: 600;
}

.ctaButton {
    background: linear-gradient(45deg, #0056b3, #00aaff);
    color: white;
    border: 2px solid  #00aaff;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 30px;
    text-align: center;
    margin: 20px 0px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.ctaButton:hover {
    background: linear-gradient(45deg, #00aaff, #0056b3);
    color: white;
}

.heroImage {
    position: relative;
    width: 40vw; /* Fixed width */
    height: 20vw; /* Fixed height */
    overflow: hidden; /* Hide overflow */
    background-color: #dddddd;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.4); /* Box shadow */
}

.heroImage img {
    width: 50%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
    transition: transform 0.3s ease, opacity 0.5s ease; /* Smooth transitions */
}

.imageSlider {
    position: relative;
    width: 100%;
    height: 100%;
}

.sliderImage {
    width: 100%;
    height: auto; /* Maintain aspect ratio */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.prevButton,
.nextButton {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 20px;
    font-size: 1.5rem;
    z-index: 1;
}

.prevButton {
    left: 10px;
}

.nextButton {
    right: 10px;
}


/* Media Queries for Small Screens */
@media (max-width: 1200px) {
  .hero {
    flex-direction: column; /* Stack text and image vertically */
    align-items: center; /* Center items */
    padding: 20px; /* Adjust padding */
  }

  .heroText {
    max-width: 100%; /* Allow text to use full width */
    text-align: center; /* Center text */
  }

  .heroText h1 {
    font-size: 2.5rem; /* Smaller font size for h1 */
  }

  .typingEffect {
    font-size: 1.5rem; /* Smaller font size for typing effect */
  }

  .ctaButton {
    font-size: 0.9rem; /* Smaller font size for button */
    padding: 8px 16px; /* Adjust padding for button */
  }

  .heroImage {
    width: 90%; /* Full width on small screens */
    padding: 10px 0px; /* Remove padding */
    height: 40vw;
    margin-top: 20px; /* Space between text and image */
  }

  .sliderImage {
    width: 100%; /* Full width for images */
    height: auto; /* Maintain aspect ratio */
  }

  .prevButton,
  .nextButton {
    font-size: 1.2rem; /* Smaller font size for buttons */
    padding: 8px; /* Adjust padding for navigation buttons */
  }
}










/*card section*/

.section {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
  }
  
  .heading {
    margin: 10px 0;
    font-size: 3rem;
    color: #555;
    font-weight: 600;
  }
  
  .cardContainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    max-width: 1000px;
  }
  
  .card {
    background: #fff;
    position: relative;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    width: 200px;
    height: 380px;
    text-align: left;
    padding: 10px;
    box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease;
  }
  
  .card:hover {
    box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.4);
    transform: scale(1.02);
    cursor: pointer;
  }
  
  .cardImage {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .cardTitle {
    font-size: 1.2rem;
    margin: 10px 0 5px;
  }
  
  .cardSubtitle {
    font-size: 1rem;
    color: #555;
    margin: 5px 0;
  }
  
  .cardPrice {
    font-size: 1.2rem;
    color: #333;
    font-weight: bold;
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
  }
  
  .ratingButton {
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
    cursor: pointer;
    margin-right: 1rem;
    display: flex;
    align-items: center;
  }
  
  .newPrice {
    color: rgb(214, 30, 30);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
  }
  
  .card a {
    text-decoration: none; /* Removes underline */
    color: inherit; /* Inherits color from parent element */
  }
  
  
  