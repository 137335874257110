.aboutContainer {
    padding: 60px;
    background: linear-gradient(135deg, #f3f4f6 0%, #ffffff 100%);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .aboutContent {
    max-width: 1200px;
    background-color: #ffffff;
    padding: 40px;
    border-radius: 15px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    animation: fadeInUp 0.8s ease;
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(50px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .heading {
    font-size: 48px;
    font-weight: 700;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .description {
    font-size: 20px;
    color: #555;
    text-align: center;
    line-height: 1.8;
    margin-bottom: 40px;
  }
  
  .cardsContainer {
    display: flex;
    gap: 30px;
    justify-content: space-between;
  }
  
  .card {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.08);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    flex: 1;
  }
  
  .card:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.12);
  }
  
  .subheading {
    font-size: 28px;
    font-weight: 600;
    color: #007bff;
    margin-bottom: 15px;
  }
  
  .cardDescription,
  .list {
    font-size: 18px;
    color: #555;
    line-height: 1.6;
  }
  
  .listItem {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  }
  
  .listItem::before {
    content: '✔';
    color: #007bff;
    margin-right: 10px;
  }
  
  .ctaSection {
    margin-top: 50px;
    text-align: center;
  }
  
  .ctaHeading {
    font-size: 36px;
    color: #333;
    font-weight: 700;
    margin-bottom: 15px;
  }
  
  .ctaDescription {
    font-size: 20px;
    color: #555;
    margin-bottom: 30px;
  }
  
  .ctaButton {
    padding: 15px 30px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 30px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .ctaButton:hover {
    background-color: #0056b3;
    transform: scale(1.05);
  }
  
  /* Responsive Design */
  @media screen and (max-width: 1000px) {
    .cardsContainer {
      flex-direction: column;
      gap: 20px;
    }
  
    .ctaHeading {
      font-size: 28px;
    }
  
    .ctaDescription {
      font-size: 18px;
    }
  
    .ctaButton {
      font-size: 16px;
    }
  }

  @media screen and (max-width: 450px) {
    .aboutContainer{
      padding: 20px;
    }
  }
  