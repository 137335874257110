.section {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}

.heading {
  margin: 10px 0;
  font-size: 3rem;
  color: #555;
  font-weight: 600;
}

.cardContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  max-width: 1000px;
}

.card {
  background: #fff;
  position: relative;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  width: 200px;
  height: 380px;
  text-align: left;
  padding: 10px;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

.card:hover {
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.4);
  transform: scale(1.02);
  cursor: pointer;
}

.cardImage {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.cardTitle {
  font-size: 1.2rem;
  margin: 10px 0 5px;
}

.cardSubtitle {
  font-size: 1rem;
  color: #555;
  margin: 5px 0;
}

.cardPrice {
  font-size: 1.2rem;
  color: #333;
  font-weight: bold;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
}

.ratingButton {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
  cursor: pointer;
  margin-right: 1rem;
  display: flex;
  align-items: center;
}

.newPrice {
  color: rgb(214, 30, 30);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
}

.card a {
  text-decoration: none; /* Removes underline */
  color: inherit; /* Inherits color from parent element */
}

@media (max-width: 768px) {
  .heading {
    font-size: 2.5rem;
  }
}

@media (max-width: 480px) {
  .heading {
    font-size: 2rem;
  }
}