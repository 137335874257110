.footer {
  background-color: #f8f8f8;
  padding: 20px 0;
  text-align: center;
  width: 100%;
  margin-top: auto; /* Pushes the footer to the bottom */
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.logoImage {
  max-width: 150px;
}

.navMenu {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.navLink {
  text-decoration: none;
  color: #333;
  font-weight: 500;
  font-size: 1.2rem;
}

.navLink:hover {
  color: #007bff;
}

.socialIcons {
  display: flex;
  justify-content: center;
  gap: 20px;
  width: 150px;
  align-items: center;
}

.socialLink {
  color: #333;
  font-size: 20px;
  text-decoration: none;
  font-size: 1.5rem;
}

.socialLink:hover {
  color: #007bff;
}

.divider {
  border: 0;
  border-top: 1px solid #ddd;
  margin: 20px 0;
}

.copyright {
  margin: 0;
  font-size: 18px;
  color: #666;
}

/* Media query for screens up to 768px */
@media (max-width: 520px) {
  .container {
    flex-direction: column;
    align-items: center;
  }

  .navMenu {
    margin-top: 15px;
    flex-direction: column;
    gap: 10px;
  }

  .socialIcons {
    margin-top: 15px;
    justify-content: center;
    width: 80px;
  }

  .logoImage {
    max-width: 80px;
  }
  .copyright {
    font-size: 14px;
  }
}