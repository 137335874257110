.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 100px;
  background-color: #B6E7FF;
}

.textContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px 20px;
}

.textContainer h2{
  font-size: 2rem;
  margin: 0;
  color: white;
}

.text {
  font-size: 3.5rem;
  margin: 0;
  color: lightseagreen;
}

.button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.imageContainer {
  flex-shrink: 0;
  border-radius: 20px;
}

.image {
  width: 250px;
  height: 250px;
  object-fit: cover;
  border-radius: 20px;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.2);
}



/* Media query for small screens */
@media (max-width: 880px) {
  .container {
    flex-direction: column; /* Stack the text and image vertically */
    padding: 20px; /* Reduce padding */
    gap: 10px;
    text-align: center; /* Center align text */
  }

  .textContainer h2 {
    font-size: 1.5rem; /* Reduce font size */
  }

  .text {
    font-size: 2.5rem; /* Reduce font size */
  }

  .image {
    width: 200px; /* Resize image */
    height: 200px;
  }
}

@media (max-width: 480px) {
  .text {
    font-size: 2rem; /* Further reduce font size */
  }

  .image {
    width: 150px; /* Further resize image */
    height: 150px;
  }
}