/* SearchBar.module.css */
.searchBarContainer {
    margin: 20px auto;
    padding: 0px 10px;
    width: 100%;
    max-width: 600px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .searchInput {
    width: 70%;
    padding: 12px 20px;
    border-radius: 25px;
    border: 2px solid #ddd;
    font-size: 16px;
    outline: none;
    transition: border-color 0.3s ease;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .searchInput:focus {
    border-color: #6c63ff;
    box-shadow: 0 0 10px rgba(108, 99, 255, 0.5);
  }
  
  .searchButton {
    padding: 12px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    font-size: 16px;
    margin-left: 10px;
    transition: background-color 0.3s ease;
  }
  
  .searchButton:hover {
    background-color: #0056b3;
  }
  