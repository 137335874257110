.loader {
    border: 8px solid #f3f3f3; /* Light grey */
    border-top: 8px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 50px; /* Size of the spinner */
    height: 50px; /* Size of the spinner */
    animation: spin 1s linear infinite;
    display: inline-block; /* Change to inline-block */
    margin: 20px auto; /* Center horizontally, add space around */
  }
  
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  